<template>
  <div>
    <h5 class="text-sm">Active Deals</h5>
    <datatable :data="cashbackSettings" :columns="columns" :actions="actions" />
  </div>
</template>
<script>
export default {
  props: {
    profile: {
      type: [Object, Array],
      required: true
    }
  },
  data() {
    return {
      columns: [
        {
          name: 'type',
          th: 'Merchant Discount Type'
        },
        {
          name: 'commission',
          th: 'Merchant Discount',
          render: settings =>
            settings?.type === 'percentage'
              ? `${settings?.commission}%`
              : this.$options.filters.formatAmount(settings?.commission)
        },
        {
          name: 'credpal_commission_type',
          th: 'Credpal Discount Type'
        },
        {
          name: 'credpal_commission',
          th: 'Credpal Discount',
          render: settings =>
            settings?.credpal_commission_type === 'percentage'
              ? `${settings?.credpal_commission}%`
              : this.$options.filters.formatAmount(settings?.credpal_commission)
        },
        {
          name: 'minimum',
          th: 'Minimum Amount',
          render: settings =>
            this.$options.filters.formatAmount(settings?.minimum) ?? 0
        },
        {
          name: 'maximum',
          th: 'Maximum Amount',
          render: settings =>
            this.$options.filters.formatAmount(settings?.maximum) ?? 0
        },
        {
          name: 'maximum_discount_value',
          th: 'Maximum Discount Value',
          render: settings =>
            this.$options.filters.formatAmount(settings?.maximum_discount_value)
        },
        {
          name: 'created_at',
          th: 'Created At',
          render: settings =>
            this.$options.filters.dateFormat(settings?.created_at, 'D, dd M Y')
        }
      ],
      actions: [
        {
          text: 'Deactivate',
          class: 'border-red-500 text-red-500 rounded-sm px-4 py-2',
          action: this.deactivateDeal
        }
      ]
    };
  },
  computed: {
    cashbackSettings() {
      return this.profile?.cashbackSettings;
    }
  },
  methods: {
    async deactivateDeal({ id }) {
      await this.sendRequest(
        'admin.merchants.changeDealStatus',
        id,
        'deactivate',
        {
          success: () => this.$emit('success'),
          error: error => console.log(error)
        }
      );
    }
  }
};
</script>
